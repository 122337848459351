<template>
    <div class="search-container" :class="[{ 'extended' : hasresults }, { 'closed' : !this.open}]">
        <div class="toggle-container" @click="toggleSidebar">
            <v-icon v-if="open" name="chevron-left" />
            <v-icon v-else name="chevron-right" />
        </div>
        <SearchBox class="upper" :class="{ 'closed' : !this.open }" @tolocationname="lastLocation" @searchresult="handleSearchResult" @fromselected="fromSelected" @toselected="toSelected"/>
        <ResultBox v-show="this.open" class="lower" :class="{ 'closed' : !this.open }" @detailsClicked="showDetails" :results="results" v-if="results" :tolocation="tolocation" @tripselected="tripSelected" @ticketsClicked="showTickets" />
        <div class="details-container-wrapper">
            <div class="details-container" :class="{ 'showdetails' : this.showdetails && this.open }">
                <TripDetailsBox @back="hideDetails" :trip="trip" @showtickets="showTickets"/>
            </div>
        </div>
        <div class="tickets-container-wrapper">
            <div class="tickets-container" :class="{ 'showdetails' : this.showtickets && this.open }" v-if="trip.hasfares">
                <TicketBox @back="hideTickets" :trip="trip"/>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/chevron-right'
import 'vue-awesome/icons/chevron-left'

import SearchBox from './systems/SearchBox.vue'
import ResultBox from './systems/ResultBox.vue'
import TripDetailsBox from './systems/TripDetailsBox.vue'
import TicketBox from './systems/TicketBox.vue'

export default {
    name: 'RoutingSearch',
    data() {
        return {
            results: null,
            tolocation: '',
            open: true,
            showdetails: false,
            showtickets: false,
            trip: { zeitmeilen: 0, interchanges: 0, legs: [] }
        }
    },
    methods: {
        showTickets: function() {
            this.showtickets = true
        },
        hideTickets: function() {
            this.showtickets = false
        },
        lastLocation: function(event) {
            this.tolocation = event
        },
        handleSearchResult: function(event) {
            if (event) {
                this.results.push(event)
            } else {
                this.results = []
                this.hideDetails()
                this.hideTickets()
            }
        },
        fromSelected: function(event) {
            this.$emit('fromselected', event)
        },
        toSelected: function(event) {
            this.$emit('toselected', event)
        },
        tripSelected: function(event) {
            // console.log(event)
            this.trip = event
            this.$emit('tripselected', event)
        },
        toggleSidebar: function() {
            this.open ^= true
        },
        showDetails: function() {
            this.showdetails = true
        },
        hideDetails: function() {
            this.showdetails = false
        }
    },
    computed: {
        hasresults: function() {
            return this.results && this.results.length > 0
        }
    },
    components: {
        SearchBox,
        ResultBox,
        TripDetailsBox,
        TicketBox
    }
}
</script>

<style scoped>
.search-container {
    height: fit-content;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 400px;
    background: #fff;
    padding-bottom: 50px;
    box-shadow: 0px 1px 10px rgb(100, 100, 150, 0.7);

    -webkit-transition: 2s;
    transition: .3s;
}

.search-container.closed {
    width: 0;
}

.search-container > .toggle-container {
    position: absolute;
    height: 50px;
    width: 20px;
    left: 400px;
    top: 150px;
    background: #fff;
    box-shadow: 0px 1px 10px rgb(100, 100, 150, 0.7);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    color: #0D93D4;
    transition: .3s;
    z-index: -1;
}

.search-container.closed > .toggle-container {
    width: 40px;
    left: 0;
}

.search-container.closed > .toggle-container > svg {
    width: 30%;
}

.search-container > .toggle-container:hover {
    cursor: pointer;
}

.search-container > .toggle-container > svg {
    height: 100%;
    width: 60%;
}

.search-container.extended {
    height: 100%;
}

.search-container > .upper {
    height: 200px;
}

.search-container > .tickets-container-wrapper,
.search-container > .details-container-wrapper {
    position: absolute;
    top: 200px;
    width: 0;
}

.search-container > .tickets-container-wrapper > .tickets-container,
.search-container > .details-container-wrapper > .details-container {
    position: relative;
    width: 0px;
    height: 100%;
    overflow: hidden;
    transition: .3s;
    background: #fff;
}

.search-container > .tickets-container-wrapper > .tickets-container.showdetails,
.search-container > .details-container-wrapper > .details-container.showdetails {
    width: 400px;
}

.search-container > .tickets-container-wrapper > .tickets-container > div,
.search-container > .details-container-wrapper > .details-container > div {
    position: absolute;
    /* right: -400px; */
    left: -400px;
    transition: .3s;
}

.search-container > .tickets-container-wrapper > .tickets-container.showdetails > div,
.search-container > .details-container-wrapper > .details-container.showdetails > div {
    /* right: 0; */
    left: 0px;
}

.search-container > .tickets-container-wrapper,
.search-container > .details-container-wrapper,
.search-container > .lower {
    height: calc(100% - 200px);
}

.search-container > .upper.closed,
.search-container > .lower.closed {
    overflow: hidden;
    padding: 10px 0px;
}

@keyframes zlast {
  0% {
    z-index: 1;
  }
  99% {
    z-index: 1;
  }
  100% {
    z-index: -1;
  }
}

@media(max-width: 650px) {
    .search-container {
        width: 350px;
    }

    .search-container > .toggle-container {
        left: 350px;
    }

    .search-container > .tickets-container-wrapper > .tickets-container.showdetails,
    .search-container > .details-container-wrapper > .details-container.showdetails {
        width: 350px;
    }
}
</style>