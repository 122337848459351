import Vue from 'vue'
import App from './App.vue'
import i18n from './plugins/i18n'
import Icon from 'vue-awesome/components/Icon'
import store from './plugins/store.js'
import toast from './plugins/toast'
import mixin from './plugins/mixin'
import router from './plugins/router.js'
import dotenv from 'dotenv'
import Config from './plugins/modules/config.js'
import dotenvExpand from 'dotenv-expand'
import keycloakcart from './plugins/keycloakcart'
import clickoutside from './plugins/clickoutside'
import vuelidate from './plugins/vuelidate'

dotenvExpand(dotenv.config())

// the config must be instantiated before anything needing the backend will work
const config = new Config(function() {
    Vue.prototype.$config = config.current()

    Vue.config.productionTip = false
    Vue.component('v-icon', Icon)
    Vue.mixin(mixin)

    keycloakcart.init()

    new Vue({
        router,
        store,
        toast,
        clickoutside,
        vuelidate,
        i18n,
        render: h => h(App),
    }).$mount('#app')
})


