export default {
    methods: {
        _leadingZero: function(digit) {
            return ('0' + digit).slice(-2)
        },
        _getIconParam: function(type) {
            // colors for these classes are in the application.css
            switch(type) {
                case 'WALK':
                    return 'walking'
                case 'TRAM':
                case 'RAIL':
                case 'METRO':
                    return 'train'
                case 'BUS':
                    return 'bus'
                case 'SELF_DRIVE_CAR':
                    return 'car'
                case 'CYCLE':
                    return 'bicycle'
                default:
                    return 'times'
            }
        },
        _getCurrencySymbol: function(currency) {
            switch(currency) {
                case 'USD':
                    return '$'
                case 'EUR':
                default:
                    return '€'
            }
        },
        _currencyValueToFloat: function(value) {
            return (value/100).toFixed(2)
        },
        _rng: function(length) {
            // generate a random string with a given length
            let result             = ''
            const characters       = 'AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz0123456789'
            for ( var i = 0; i < length; i++ ) {
                result += characters.charAt(Math.floor(Math.random() * characters.length))
            }
            return result
        },
        _getDateTimeString: function(unixdate, withday, locale = 'de') {
            let date = new Date(unixdate)
            let days
            let appendix
            let day = this._leadingZero(date.getDate())
            let month = this._leadingZero(date.getMonth() +1)
            let year = date.getFullYear()
            let hours
            let minutes = this._leadingZero(date.getMinutes())

            if (locale === 'de') {
                days = ['Mo.','Di.','Mi.','Do.','Fr.','Sa.','So.']
                appendix = 'Uhr'
                hours = date.getHours()
            } else {
                days = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']
                appendix = date.getHours() > 12 ? 'PM' : 'AM'
                hours = date.getHours() % 12
                hours = hours ? hours : 12; // the hour '0' should be '12'
            }

            hours = this._leadingZero(hours)

            let weekday = withday ? days[date.getDay()] + ' ' : ''

            return `${weekday}${day}.${month}.${year} ${hours}:${minutes} ${appendix}`
        },
        _getDateString: function(unixdate, withday, locale = 'de') {
            let date = new Date(unixdate)
            let days
            let day = this._leadingZero(date.getDate())
            let month = this._leadingZero(date.getMonth() +1)
            let year = date.getFullYear()

            if (locale === 'de') {
                days = ['Mo.','Di.','Mi.','Do.','Fr.','Sa.','So.']
            } else {
                days = ['Mon','Tue','Wed','Thu','Fri','Sat','Sun']
            }

            let weekday = withday ? days[date.getDay()] + ' ' : ''

            return `${weekday}${day}.${month}.${year}`
        }
    }
}