<template>
    <div class="sandbar-container">
        <div v-if="kcloading" class="loading">
            <LoadingEllipsis />
        </div>
        <div v-else class="sandbar">
            <div v-if="keycloak.authenticated" class="account" @click="toggleAccountDetails">
                <div>
                    <div class="name">
                        <label>{{ keycloak.tokenParsed.email }}</label>
                    </div>
                </div>
            </div>
            <div v-else class="login" >
                <input type="button" :value="$t('sandbar.signin')" @click="signIn"/>
            </div>
            <div class="cart">
                <div class="picture" @click="toggleCartDetails">
                    <div class="icon">
                        <v-icon name="shopping-cart" />
                    </div>
                    <div class="cart-amount" v-if="cart.items.length > 0">
                        <label>{{ this.$cart.getTotalAmount() }}</label>
                    </div>
                </div>
            </div>
            <div v-show="cartdetailsopen" class="details" :class="{ 'with-cart' : cart.items.length > 0 }" v-click-outside="closeDetails">
                <svg height="20" width="20" viewBox="0,0,20,20">
                    <polygon points="10,0 20,20 0,20" fill="#fff"/>
                </svg>

                <div class="cart-content">
                    <ShoppingCartInset :items="this.cart.items"/>
                    <div :disabled="!cart.lastpurchase" class="qr-wrapper" @click="showLastTicket">
                        <v-icon name="qrcode"/>
                    </div>
                </div>

            </div>
            <div v-show="accountdetailsopen" class="accountdetails" v-click-outside="closeAccountDetails">
                <svg width="20" height="30" viewBox="0,0,20,30">
                    <polygon points="10,0 20,30 0,30" fill="#fff"/>
                </svg>

                <div class="account-header">
                    <label>{{ $t('sandbar.account.header')}}</label>
                    <v-icon v-if="!showsettings" name="map-marked-alt" scale="1.5" :title="$t('sandbar.settings')" @click="togglesettings"/>
                    <v-icon v-else name="ticket-alt" scale="1.75" :title="$t('sandbar.tickets')" @click="togglesettings"/>
                    <v-icon name="sign-out-alt" scale="1.5" :title="$t('sandbar.logout')" @click="logout"/>
                </div>
                <div v-if="keycloak.authenticated" class="account-details">
                    <div class="name">
                        <label>{{ keycloak.tokenParsed.name }}</label>
                    </div>
                     <div class="email">
                        <label>{{ keycloak.tokenParsed.email }}</label>
                    </div>
                </div>

                <div v-if="!showsettings" class="ticket-header">
                    <label >{{ $t('sandbar.account.tickets') }}</label>
                </div>

                <div v-if="!showsettings && Object.keys(tickets).length > 0 && !ticketsloading" class="ticket-wrapper" >
                    <div class="ticket-content" v-for="ticketgroup in Object.keys(tickets)" :key="ticketgroup">
                        <label >{{ ticketgroup }}</label>
                        <PurchasedTicketDepiction v-for="ticket in tickets[ticketgroup]" :key="ticket.id" :ticket="ticket" class="depiction"/>
                    </div>
                </div>
                <div v-if="Object.keys(tickets).length === 0 && !ticketsloading" class="empty">
                    <label>{{ $t('sandbar.account.notickets') }}</label>
                </div>
                <div v-if="!showsettings && ticketsloading" class="loading">
                    <LoadingEllipsis color="#064A6C"/>
                </div>

                <div v-if="showsettings">
                    <ProfileSwitcherPage />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import LoadingEllipsis from '../systems/elements/LoadingEllipsis.vue'
import ShoppingCartInset from '../systems/elements/ShoppingCartInset.vue'
import PurchasedTicketDepiction from './PurchasedTicketDepiction.vue'

import ProfileSwitcherPage from '../../pages/ProfileSwitcherPage.vue'

import Vue from 'vue'
import axios from 'axios'

import getEnv from '@/utils/env'

import 'vue-awesome/icons/shopping-cart'
import 'vue-awesome/icons/qrcode'
import 'vue-awesome/icons/sign-out-alt'
import 'vue-awesome/icons/map-marked-alt'
import 'vue-awesome/icons/ticket-alt'

export default {
    name: 'AccountSandbar',
    data() {
        return {
            recent: false,
            accountrecent: false,
            cartdetailsopen: false,
            accountdetailsopen: false,
            kcloading: true,
            ticketlist: [],
            ticketsloading: false,
            showsettings: false
        }
    },
    mounted() {
        // the keycloak adapter needs a few seconds to get the current state
        // so we show a loading spinner for the time being to avoic
        // flickering of the sign-in button/account view
        setTimeout(() => {
            this.kcloading = false
        }, 3000)
    },
    methods: {
        togglesettings: function() {
            this.showsettings ^= 1
        },
        showLastTicket: function() {
            if (this.cart.lastpurchase) {
                this.recent = true
                this.$emit('lastTicket')
            }
        },
        signIn: function() {
            this.keycloak.login()
        },
        toggleCartDetails: function() {
            this.accountdetailsopen = false
            this.cartdetailsopen ^= true

            if (this.cartdetailsopen) {
                this.recent = true
            }

        },
        toggleAccountDetails: function() {
            this.cartdetailsopen = false
            this.accountdetailsopen ^= true

            this.ticketlist = []
            this.ticketsloading = true

            let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_ACCOUNT_ENDPOINT
            url += `/${this.keycloak.tokenParsed.sub}`
            url += "/tickets"

            axios.get(url)
            .then(response => {
                this.ticketlist = response.data

                this.ticketlist = this.ticketlist.sort(function(a, b) {
                    return new Date(a.validfrom) - new Date(b.validfrom)
                })

                this.ticketsloading = false
            })
            .catch(error =>{
                Vue.$toast.error(error)
                this.ticketsloading = false
            })

            if (this.accountdetailsopen) {
                this.accountrecent = true
            }

        },
        closeDetails: function() {
            // click outside is triggered after the openDetails function
            // so we catch the first click outside
            if (this.recent) {
                this.recent = false
            } else {
                this.cartdetailsopen = false
            }
        },
        closeAccountDetails: function() {
            // click outside is triggered after the openDetails function
            // so we catch the first click outside
            if (this.accountrecent) {
                this.accountrecent = false
            } else {
                this.accountdetailsopen = false
            }
        },
        logout: function() {
            this.keycloak.logout()
        }
    },
    computed: {
        keycloak: function() {
            return this.$store.state.keycloak
        },
        token: function() {
            return this.$store.state.keycloaktoken
        },
        cart: function() {
            return this.$store.state.cart
        },
        tickets: function(){
            let result = {}

            this.ticketlist.forEach(entry => {
                const date = this._getDateString(entry.validfrom)

                if (!result[date]) {
                    result[date] = []
                }

                result[date].push(entry)
            })

            return result
        }
    },
    components: {
        LoadingEllipsis,
        ShoppingCartInset,
        PurchasedTicketDepiction,
        ProfileSwitcherPage
    }
}
</script>

<style scope,
        ProfileSwitcherPaged>
.sandbar-container {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 0 10px;
    height: 50px;
    z-index: 1;
}

.sandbar-container > .loading {
    position: absolute;
    top: 20px;
    right: 30px;
}

.sandbar-container > .sandbar {
    position: relative;
}

.sandbar-container > .sandbar > .account,
.sandbar-container > .sandbar > .cart > .picture {
    position: relative;
    color: #064A6C;
    font-weight: bold;
}

.sandbar-container > .sandbar > .cart > .picture:hover,
.sandbar-container > .sandbar > .account > div > .name:hover,
.sandbar-container > .sandbar > .account > div > .name > label:hover {
    cursor: pointer;
}

.sandbar-container > .sandbar > .account > div {
    position: absolute;
    right: 45px;
    top: 10px;
    background: #fff;
    /* padding: 5px 15px; */
    font-size: 1.2em;
    border-radius: 5px;
    box-shadow: 0px 1px 10px rgb(100 100 150 / 70%);
    overflow: hidden;
}

.sandbar-container > .sandbar > .account > div > .name {
    padding: 5px 15px;
}

.sandbar-container > .sandbar > .account > div > .name:hover {
    background-color: #064A6C22;
}

.sandbar-container > .sandbar > .cart > .picture {
    position: absolute;
    right: 0;
    background: #fff;
    border-radius: 50%;
    height: 50px;
    width: 50px;
    box-shadow: 0px 1px 10px rgb(100 100 150 / 70%);
}

.sandbar-container > .sandbar > .cart > .picture > .icon > svg {
    height: 100%;
    width: 100%;
    padding: 13px;
    box-sizing: border-box;
}

.sandbar-container > .sandbar > .cart > .picture > .icon:hover {
    border-radius: 50%;
    background: #064A6C22;
}

.sandbar-container > .sandbar > .cart > .picture > .cart-amount {
    position: absolute;
    border-radius: 50%;
    background: #33AAFF;
    width: 25px;
    height: 25px;
    z-index: 100;
    color: #fff;
    top: -10px;
    right: -10px;
    line-height: 25px;
    box-shadow: 0px 1px 10px rgb(100 100 150 / 70%);
}

.sandbar-container > .sandbar > .cart > .picture > .cart-amount:hover,
.sandbar-container > .sandbar > .cart > .picture > .cart-amount > label:hover {
    cursor: pointer;
}
.sandbar-container > .sandbar > .accountdetails,
.sandbar-container > .sandbar > .details {
    position: absolute;
    right: 0;
    top: 75px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0px 1px 10px rgb(100 100 150 / 70%);
    padding: 10px;
    height: 140px;
}

.sandbar-container > .sandbar > .details.with-cart {
    height: 450px;
}

.sandbar-container > .sandbar > .accountdetails {
    height: 450px;
    width: 350px;
    text-align: left;
}

.sandbar-container > .sandbar > .accountdetails > .loading {
    margin: 20px auto;
    width: fit-content;
}

.sandbar-container > .sandbar > .accountdetails > .account-header {
    color: #064A6C;
    font-size: 1.3em;
    position: relative;
    font-weight: bold;
}

.sandbar-container > .sandbar > .accountdetails > .account-header > svg:nth-of-type(2) {
    position: absolute;
    color: red;
    right: 0;
    padding: 5px;
}

.sandbar-container > .sandbar > .accountdetails > .account-header > svg:nth-of-type(1) {
    position: absolute;
    right: 45px;
    padding: 5px;
}

.sandbar-container > .sandbar > .accountdetails > .account-header > svg:hover {
    cursor: pointer;
    background: #00000011;
    border-radius: 5px;
}

.sandbar-container > .sandbar > .accountdetails > .ticket-header {
    text-align: center;
    margin-top: 20px;
    font-weight: bold;
}

.sandbar-container > .sandbar > .accountdetails > svg {
    position: absolute;
    top: -30px;
    right: 60px;
}

.sandbar-container > .sandbar > .accountdetails > .account-details {
    margin: 5px 0 0 10px;
}

.sandbar-container > .sandbar > .details > .cart-content {
    height: 100%;
    position: relative;
}

.sandbar-container > .sandbar > .details > .cart-content > .qr-wrapper {
    position: absolute;
    left: 0;
    bottom: 5px;
}

.sandbar-container > .sandbar > .details > .cart-content > .qr-wrapper > svg {
    height: 28px;
    width: 28px;
    padding: 3px;
    box-sizing: border-box;
}

.sandbar-container > .sandbar > .details > .cart-content > .qr-wrapper[disabled="disabled"] > svg {
    color: #ccc;
}

.sandbar-container > .sandbar > .details > .cart-content > .qr-wrapper:not([disabled]) > svg:hover {
    cursor: pointer;
    background: #064A6C22;
}

.sandbar-container > .sandbar > .details > svg {
    position: absolute;
    top: -20px;
    right: 15px;
}

.sandbar-container > .sandbar > .login {
    position: absolute;
    background: #fff;
    border-radius: 5px;
    right: 40px;
    top: 10px;
    padding-right: 10px;
    box-shadow: 0px 1px 10px rgb(100 100 150 / 70%);
}

.sandbar-container > .sandbar > .accountdetails > .ticket-wrapper {
    height: calc(100% - 90px);
    overflow-y: auto;
}

.sandbar-container > .sandbar > .accountdetails > .ticket-wrapper > .ticket-content {
    text-align: left;
    margin-bottom: 30px;
}

.sandbar-container > .sandbar > .accountdetails > .ticket-wrapper > .ticket-content > .depiction {
    padding-left: 20px;
    box-sizing: border-box;
}

.sandbar-container > .sandbar > .accountdetails > .ticket-wrapper > .ticket-content > .depiction:hover {
    background: #00000011;
    cursor: pointer;
}

.sandbar-container > .sandbar > .accountdetails > .ticket-wrapper > .ticket-content > .depiction:hover >>> label {
    cursor: pointer;
}

.sandbar-container > .sandbar > .accountdetails > .ticket-wrapper > .ticket-content > label {
    font-weight: bold;
    font-size: 1.2em;
    color: #064A6C;
    margin: 10px 0 0 5px;
    display: block;
}
.sandbar-container > .sandbar > .accountdetails > .empty {
    margin: 10px 0 30px 0;
    font-size: 1.3em;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
    padding: 20px;
    text-align: center;
}

.sandbar-container > .sandbar > .login > input[type=button] {
    color: #064A6C;
}

.sandbar-container > .sandbar > .login > input[type=button]:hover {
    background: #064A6C22;
}

@media(max-width: 650px) {
    .sandbar-container > .loading {
        right: -10px;
    }

    .sandbar-container > .sandbar > .login {
        right: -10px;
        top: -10px;
        padding: 0;
    }

    .sandbar-container > .sandbar > .cart > .picture {
        top: 35px;
        right: -10px;
    }

    .sandbar-container > .sandbar > .account > div {
        right: -10px;
        top: -10px;
        padding: 0;
        max-width: 125px;
    }

    .sandbar-container > .sandbar > .account > div > .name {
        padding: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .sandbar-container > .sandbar > .details {
        right: -10px;
        top: 110px;
    }
}

</style>