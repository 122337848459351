<template>
    <div class="popup-background">
        <div class="popup-container">
            <div class="header-container">
                <label class="header">{{ this.title }}</label>
                <v-icon name="times" scale="1" class="close-button" @click="close(false)"/>
            </div>

            <div class="content-container">
                <slot>Are you sure?</slot>
            </div>

            <div class="popup-button-container" v-if="!buttonless">
                <input :disabled="isloading" type="button" class="button" @click="close(true)" :value="submitlabel"/>
                <input :disabled="isloading" type="button" class="button" @click="close(false)" :value="cancellabel"/>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/times'

export default {
    name: 'Popup',
    props: {
        title: String,
        isopen: {
            type: Boolean,
            default: false
        },
        submitlabel: {
            type: String,
            default: 'Yes'
        },
        cancellabel: {
            type: String,
            default: 'No'
        },
        isloading: {
            type: Boolean,
            default: false
        },
        buttonless: Boolean
    },
    mounted() {
        document.body.classList.toggle('noscroll', true)
    },
    methods: {
        close: function(success) {
            // prevent closing the dialog while something is loading
            if (!this.isloading) {
                document.body.classList.toggle('noscroll', false)
                this.$emit('close', success)
            }
        }
    }
}
</script>

<style scoped>
.popup-background {
    background: rgba(0, 0, 0, 0.5);
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}

.popup-container {
    background: #fff;
    position: relative;
    box-sizing: border-box;
    height: calc(100% - 100px);
    max-height: 650px;
    width: 100%;
    max-width: 500px;
    margin: auto;
    margin-top: 80px;
    margin-bottom: 20px;
    padding: 20px;
    border-radius: 5px;

    /* top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%); */
}

.header-container {
    font-weight: bold;
    font-size: 1.2em;
    text-align: left;
}

.content-container {
    margin-top: 20px;
    height: calc(100% - 40px);
    overflow-y: auto;
    box-sizing: border-box;
}

.popup-button-container {
    width: 100%;
    text-align: left;
    margin-top: 20px;
}

.close-button {
    float: right;
    color: #777;
}

.close-button:hover {
    cursor: pointer;
}
</style>