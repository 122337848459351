<template>
    <div class="locale-changer">
<!--         <select v-model="$i18n.locale">
            <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
        </select> -->

        <div class="locale-changer">
            <div v-for="(lang, i) in langs" :key="`Lang${i}`" class="locale" :class="{ 'active' : $i18n.locale === lang }" @click="changelocale(lang)">
                <label>{{ lang }}</label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'locale-changer',
    data () {
        return { 
            langs: ['de', 'en']
        }
    },
    methods: {
        changelocale: function(locale) {
            this.$i18n.locale = locale
        }
    }
}
</script>

<style scoped>
.locale-changer {
    float: right;
    display: flex;
    justify-content: space-evenly;
    height: 100%;
    align-items: center;
}

.locale {
    padding: 0 5px;
    color: #aaa;
    text-transform: uppercase;
    font-weight: bold;
}

.locale:hover >>> * {
    cursor: pointer;
}

.locale.active {
    color: #fff;
}

.locale:nth-child(odd) {
    border-right: 1px solid #aaa;
}
</style>