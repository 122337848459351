import Vue from 'vue'
import Cart from './modules/cart.js'
import store from './store.js'
import axios from 'axios'
import Keycloak from 'keycloak-js'
import getEnv from '@/utils/env'

export default {
    init: function() {
        let initoptions = {
            url: Vue.prototype.$config.keycloakconfig.baseurl,
            realm: Vue.prototype.$config.keycloakconfig.realm,
            clientId: Vue.prototype.$config.keycloakconfig.client
        }

        // Always register a guest cart
        // kc onauthsuccess will then register one with a user id
        Vue.prototype.$cart = new Cart(btoa('guest'), (cart) => { store.commit('newCart', cart) })

        if (!store.state.keycloak) {
            // https://github.com/keycloak/keycloak-documentation/blob/master/securing_apps/topics/oidc/javascript-adapter.adoc
            let keycloak = Keycloak(initoptions)

            keycloak.init({
                onLoad: 'check-sso',
                silentCheckSsoRedirectUri: window.location.origin + '/scsso.html'
            })
            // eslint-disable-next-line no-unused-vars
            .then(auth => {
                setInterval(() => {
                    if (store.state.keycloak.authenticated) {
                        // eslint-disable-next-line no-unused-vars
                        keycloak.updateToken(900).then((refreshed) => {
                            // console.log(refreshed)
                        }).catch(() => {
                            keycloak.logout()
                        })
                    }
                }, 6000)
            })
            .catch(err => {
                console.log(err)

                if (err && err.error_description) {
                    Vue.$toast.error(err.error_description.replaceAll('+', ' '))
                }
            })

            keycloak.onAuthSuccess = function() {
                // after a successfull login, we register a cart prototype
                // with the user id for later use
                Vue.prototype.$cart = new Cart(this.tokenParsed.sub, (cart) => { store.commit('newCart', cart) })

                // get/register user @nps via the middleware
                const userid    = this.tokenParsed.sub
                const email     = this.tokenParsed.email
                const firstname = this.tokenParsed.given_name
                const lastname  = this.tokenParsed.family_name

                // PATCH '/account/userprofile/:profileid'
                let url = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_ACCOUNT_USERPROFILE_ENDPOINT
                url += `/${userid}`

                const body = {
                    info: {
                        email: email,
                        firstname: firstname,
                        lastname: lastname
                    }
                }

                axios.patch(url, body)
                .then(() => {
                    let useridurl = getEnv('VUE_APP_MIDDLEWARE_URL') + process.env.VUE_APP_MIDDLEWARE_NPS_USER_IDENTIFY_KEYCLOAK_USER
                    useridurl += `/${userid}`

                    return axios.get(useridurl)
                })
                .then(response => {
                    keycloak.npsuserid = response.data.userid
                })
                .catch(err => {
                    Vue.$toast.error(err)
                })
            }

            keycloak.onTokenExpired = function() {
                store.commit('newKeyclaokInstance', null)
                Vue.$toast.warning('Your session has expired')
            }

            store.commit('newKeyclaokInstance', keycloak)
        }
    }
}