// cookie based shopping cart, currently made for the ticket objects from "the middleware"
// to be used as instance, e.g. as a prototype 'Vue.prototype.$cart = new Cart(userid)

// the cookie value looks like this:
/*
    {
        lastmodified: [date],
        items: [
            {
                amount: [number],
                id: [number],
                data: [object] -> original object.
                payment: middleware -> definitions -> ticketservice.d.ts
            }
        ],
        lastpurchase: {
            order: [object],
            price: [number]
        }
    }
*/

export default class Cart {

    // private field to hold the cookiename
    #cookiename

    // method that is called after the cart has changed
    // allows to store the cart for e.g. in vuex for easier
    // computed/watched props
    #callback // (cart: TicketCart) => void

    constructor(userid, updatecallback) {
        this.#cookiename = `__hqcart${userid}__`
        this.#callback = updatecallback
        // init cart, so there will at least be an empty one
        // and return it
        let cart = this.#getCart()
        this.#callback(cart)
    }

    #createCart = function() {
        let cart = {
            lastmodified: new Date(),
            items: [],
            lastpurchase: null
        }
        this.#saveCart(cart)
    }

    #saveCart = function(cart) {
        cart.lastmodified = new Date()
        // 'emit' the new cart
        this.#callback(cart)
        // workaround for non ascii chars
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa
        localStorage[this.#cookiename] = btoa(this.#toBinary(JSON.stringify(cart)))
    }

    #getCart = function() {
        // if there is no cart present, create one
        if (!localStorage[this.#cookiename]) {
            this.#createCart()
        }
        // workaround for non ascii chars
        // https://developer.mozilla.org/en-US/docs/Web/API/WindowOrWorkerGlobalScope/btoa
        return JSON.parse(this.#fromBinary(atob(localStorage[this.#cookiename])))
    }

    updatePaymentInfo = function(payment) {
        let cart = this.#getCart()
        cart.items.forEach(item => {
            item.payment.paymentInfos = payment
            item.payment.paymentInfos.saleschannel = '2' // app == '1', web == '2'
        })
        this.#saveCart(cart)
    }

    updatePayerInfo = function(firstname, lastname) {
        let cart = this.#getCart()
        cart.items.forEach(item => {
            item.payment.passenger.firstName = firstname
            item.payment.passenger.lastName = lastname
        })
        this.#saveCart(cart)
    }

    addItemToCart = function(ticketid, ticket, trip, firstname, lastname) {
        let cart = this.#getCart()

        let existingitem = cart.items.find(entry => entry.id === ticketid)

        if (existingitem) {
            // if the item is already in the list, we simply raise the amount
            existingitem.amount += 1
        } else {
            // else we add a new item
            let ticketpriceclass = (ticket.extension && ticket.extension.any) ? ticket.extension.any.priceClass : undefined

            let newitem = {
                amount: 1,
                id: ticketid,
                data: ticket,
                payment: {
                    paymentInfos: {
                        paymentdata: null, // payment id, will be added after the payment approval
                        paymentprovider: null,
                        userToken: null,
                        paymenttype: null,
                        saleschannel: '2' // app == 1, web == 2
                    },
                    product: {
                        price: ticket.price,
                        priceclass: ticketpriceclass,
                        prodid: ticket.ticketId,
                        prodname: ticket.ticketName,
                        prodorgid: parseInt(ticket.faresAuthorityRef.value) || undefined, // only used if it's a number...
                            // NOT NEEDED but defined in the API:
                            // surcharge: undefined
                    },
                    relation: {
                        destinationId: undefined, // --> only if drt
                        destinationName: trip.end.locationname,
                        originId: undefined, // --> only if drt
                        originName: trip.start.locationname,
                        lineName: trip.legs[trip.fares[0].fromTripLegIdRef].line, // from leg == 0, TODO used?
                            // NOT NEEDED but defined in the API:
                            // drivingServiceProviderIdPrimary: undefined,
                            // drivingServiceProviderIdSecondary: undefined
                    },
                    trip: {
                        departureTime: trip.start.time,
                        arrivalTime: trip.end.time,
                        destinationId: undefined, // TODO
                        destinationName: trip.end.locationname,
                        duration: trip.duration,
                        originId: undefined, // TODO
                        originName: trip.start.locationname,
                            // NOT NEEDED but defined in the API:
                            // loadFactor: undefined,
                            // startPoint: {
                            //     coordinates: {
                            //         latitude: null,
                            //         longitude: null
                            //     }
                            // },
                            // endPoint: {
                            //     coordinates: {
                            //         latitude: null,
                            //         longitude: null
                            //     }
                            // }
                    },
                    appVersion: '0', // unused
                    passenger: {
                        passengerTypeId: 1, // always '1'
                        firstName: firstname,
                        lastName: lastname
                    }
                }
            }

            // in case of DRT/ondemand we need to pass an extension
            // non drt trips shoulnd't sport the property, in that
            // case 'undefined' is assigned which will result in
            // the payment not having the extension -> should work
            newitem.payment.onDemandExtension = trip.ondemandextension

            cart.items.push(newitem)
        }

        this.#saveCart(cart)
    }

    setLastPurchase = function(order, price) {
        let cart = this.#getCart()
        order.price = price
        cart.lastpurchase = order
        this.#saveCart(cart)
    }

    clearCart = function() {
        let cart = this.#getCart()
        cart.items = []
        this.#saveCart(cart)
    }

    removeItemFromCart = function(ticketid, all = false) {
        let cart = this.#getCart()

        // we try to get the index of the given item by its id
        const itemindex = cart.items.findIndex(entry => entry.id === ticketid)
        if (itemindex !== -1) {
            // if we actually find an item with the given id
            if (all || cart.items[itemindex].amount === 1) {
                // and the all param is set, or there is only one left,
                // we remove it by splicing the array at the given
                // index by 1
                cart.items.splice(itemindex, 1)
            } else {
                // else we just remove one of the items
                cart.items[itemindex].amount -= 1
            }
        }

        this.#saveCart(cart)
    }

    updateItemAmount = function(ticketid, newamount) {
        let cart = this.#getCart()

        let existingitem = cart.items.find(entry => entry.id === ticketid)

        if (existingitem) {
            existingitem.amount = newamount
        }

        this.#saveCart(cart)
    }

    getTotalAmount = function() {
        const cart = this.#getCart()
        let amount = 0
        cart.items.forEach(item => { amount += item.amount })
        return amount
    }

    getTotalPrice = function() {
        const cart = this.#getCart()
        let price = 0
        cart.items.forEach(item => { price += item.data.price * item.amount })
        return price.toFixed(2) // fix floating point error to 2 digits
    }

    getCurrency = function() {
        const cart = this.#getCart()
        return cart.items[0].data.currency
    }

    #toBinary = function(string) {
        const codeUnits = new Uint16Array(string.length);
        for (let i = 0; i < codeUnits.length; i++) {
          codeUnits[i] = string.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint8Array(codeUnits.buffer));
    }

    #fromBinary = function(binary) {
        const bytes = new Uint8Array(binary.length);
        for (let i = 0; i < bytes.length; i++) {
          bytes[i] = binary.charCodeAt(i);
        }
        return String.fromCharCode(...new Uint16Array(bytes.buffer));
    }
}