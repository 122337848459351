<template>
    <div class="details-wrapper">
        <div class="zeitmeilen-container" v-if="this.trip.zeitmeilen">
            <label>ca. {{ this.trip.zeitmeilen }}</label>
            <svg width="20" height="20" viewBox="0,0,20,20">
                <circle cx="10" cy="10" r="8" stroke-width="2" fill="rgba(255, 255, 255, 0)" />
                <line x1="14.75" y1="2.75" x2="2.75" y2="14.75" stroke-width="2" fill="rgba(255, 255, 255, 0)" />
                <line x1="17.25" y1="5.25" x2="5.25" y2="17.25" stroke-width="2" fill="rgba(255, 255, 255, 0)" />
            </svg>
        </div>
        <div class="back-button" @click="goBack">
            <v-icon name="times" />
        </div>
        <div class="info-header-container">
                <label>{{ $t('landing.details.duration') }} <span>{{ this.triptime }}</span> h</label>
                <label v-if="this.trip.interchanges"> - <span>{{ this.trip.interchanges }}</span> {{ this.trip.interchanges === 1 ? $tc('landing.details.interchanges', 1) : $tc('landing.details.interchanges', 2) }}</label>
            </div>
        <div class="details-container">
            <div class="trip-depiction-container">
                <TripPartDepiction :leg="leg" v-for="leg in this.trip.legs" :key="JSON.stringify(leg)"/>
            </div>
        </div>
        <div class="button-container" v-if="this.trip.hasfares">
            <input class="ticket-button" type="button" :value="$t('landing.details.buttons.tickets')" @click="showTickets">
        </div>
    </div>
</template>

<script>
import TripPartDepiction from './elements/TripPartDepiction.vue'

import 'vue-awesome/icons/times'

export default {
    name: 'TripDetailsBox',
    props: {
        trip: Object
    },
    methods: {
        goBack: function() {
            this.$emit('back')
        },
        showTickets: function() {
            this.$emit('showtickets', true)
        }
    },
    computed: {
        triptime: function() {
            if (this.trip) {
                let hours = Math.floor(this.trip.duration / 60)
                let minutes = this.trip.duration % 60
                return `${this._leadingZero(hours)}:${this._leadingZero(minutes)}`
            }
            return 0
        },
        changecount: function() {
            return 2
        },
        hastransit: function() {
            let hastransit = false

            this.trip.legs.forEach(leg => {
                if (leg.type !== 'WALK') {
                    hastransit = true
                }
            })

            return hastransit
        }
    },
    components: {
        TripPartDepiction
    }
}
</script>

<style scoped>
.details-wrapper {
    width: 100%;
    height: calc(100% - 50px);
    background: #fff;
}

.details-container {
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
}

.details-wrapper > .back-button {
    margin-top: 10px;
    margin-right: 10px;
    margin-left: auto;
    width: fit-content;
    color: #777;
}

.details-wrapper > .back-button:hover,
.details-wrapper > .back-button > label:hover {
    cursor: pointer;
}

.details-wrapper > .back-button > svg {
    height: 15px;
}

.details-wrapper > .info-header-container {
    margin: 20px 0 30px 0;
    font-size: 1.2em;
    font-weight: bold;
    color: #064A6C;
}

.details-container >.trip-depiction-container {
    margin-bottom: 160px;
}

.details-container >.trip-depiction-container > div {
    margin-bottom: 10px;
}

.details-wrapper > .button-container {
    position: absolute;
    bottom: 0;
    width: calc(100% - 6px);
    background: #fff;
    padding: 10px 0;
}

.details-wrapper > .zeitmeilen-container {
    position: absolute;
    left: 10px;
    top: 10px;
    font-weight: bold;
    color: #22A136;
    stroke: #22A136;
    display: flex;
    font-size: 1em;
    line-height: 17px;
}

.details-wrapper > .zeitmeilen-container > svg {
    height: 17px;
    transform: scale(.8)
}

.details-wrapper > .button-container > .ticket-button {
    color: #064A6C;
}

.details-wrapper > .button-container > .ticket-button:hover {
    background: #064A6C22;
}
</style>