<template>
    <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
    <div class="textinput-container" v-else>
        <DatePicker :clearable="false" :format="format" :type="displaytype" v-if="!display"  :value="value" @input="handleInput" :disabled="disabled"/>
        <!-- <input @click="clicked" v-if="!display" :type="type" :value="value" @input="handleInput($event.target.value)" :disabled="disabled"/> -->
        
        <slot v-if="!display" ></slot>
        <label v-else>{{ value }}</label>
    </div>
</template>

<script>
import 'vue2-datepicker/index.css'

import DatePicker from 'vue2-datepicker'

export default {
    name: 'DateInput',
    props: {
        display: {
            type: Boolean,
            default: false
        },
        value: {
            type: [Date, String]
        },
        isloading: {
            type: Boolean,
            default: false
        },
        time: Boolean,
        disabled: Boolean,
        displaytype: {
            type: String,
            default: 'date'
        }
    },
    computed: {
        type: function() {
            return this.time ? 'datetime-local' : 'date'
        },
        format: function() {
            if (this.displaytype === 'date') {
                return 'DD.MM.YYYY'
            } else if (this.displaytype === 'datetime') {
                return 'DD.MM.YYYY  -  HH:mm'
            } else {
                return 'X' // -> unix timestamp, so it's obvious that there is no format
            }
        }
    },
    methods: {
        handleInput: function(value) {
            this.$emit('input', value)
        },
        clicked: function(event) {
            this.$emit('click', event)
        }
    },
    components: {
        DatePicker
    }
}
</script>

<style scoped>
.textinput-container {
    width: 100%;
    height: 30px;
}

.textinput-container > input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    padding-left: 10px
}

.textinput-container > label {
    color: #fff;
    font-weight: bold;
}

.textinput-container > div {
    width: 100%;
}

.textinput-container >>> input {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border: none;
    border-radius: 5px;
    padding-left: 10px;
}
</style>

<style>
/* default overrides for the datepicker classes */

*[class*='mx-'] {
    color: black;
    font-family: inherit;
}

.mx-icon-calendar {
    color: rgb(84, 84, 84);
}

.mx-input:disabled {
    color: rgb(84, 84, 84);
    background: rgba(239, 239, 239, 0.3);
}

.mx-icon-calendar:hover {
    cursor: pointer;
}
</style>