<template>
    <div id="footer" v-if="!hidefooter">
        <div class="loading-overlay" v-if="isloading"></div>
        <div class="link-container">
            <div class="link" v-for="link in links" :key="link.id">
                <a :href="link.link" target="_blank">{{ $t(link.text) }}</a>
            </div>
        </div>
        <LanguageChanger class="changer"/>
    </div>
</template>

<script>
import LanguageChanger from './systems/elements/LanguageChanger.vue'

export default {
    name: 'Footer',
    data() {
        return {
            links: []
        }
    },
    mounted() {
        this.links.push({
            id: 0,
            text: this.$config.legalconfig.publishername,
            link: this.$config.legalconfig.publisherlink
        })
        this.links.push({
            id: 1,
            text: 'footer.about',
            link: this.$config.legalconfig.aboutlink
        })
        this.links.push({
            id: 2,
            text: 'footer.privacy',
            link: this.$config.legalconfig.privacylink
        })
    },
    computed: {
        isloading: function() {
            return this.$store.state.footerloading
        },
        hidefooter: function() {
            return this.$route.meta.nofooter
        }
    },
    components: {
        LanguageChanger
    }
}
</script>

<style scoped>
#footer {
    height: 30px;
    width: 100%;
    background: #0069B4;
    /* box-shadow: 0px 1px 10px rgb(100 100 150 / 70%); */
    z-index: 9999;
    position: relative;
}

.loading-overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background: #000000bb;
}

.link-container {
    line-height: 30px;
    width: 400px;
    display: flex;
    justify-content: space-around;
}

.link-container > .link {
    margin: 0 20px;
}

.link-container > .link > a {
    text-decoration: none;
    font-weight: bold;
    color: #fff;
}

.link-container > .link > a:hover {
    color: #ccc;
}

.changer {
    position: absolute;
    right: 20px;
    top: 0;
}
</style>