<template>
    <div v-if="isloading" class="input-line-loading-placeholder shimmer big"></div>
    <div v-else class="input-wrapper" :class="{ 'error' : error }">
        <input :id="inputid" @click="clicked" v-if="!display" :class="{ 'with-label' : this.label }" type="text" :value="value" @input="handleInput($event.target.value)" :placeholder="placeholder" :disabled="disabled"/>
        <slot v-if="!display" ></slot>
        <label v-else>{{ value }}</label>
        <label :for="inputid" class="input-label" v-if="label">{{ this.label }} {{ required ? '*' : '' }}</label>
        <div class="error-container">
            <label class="error-label" :class="{ 'show' : error }">{{ this.errortext }}</label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TextInput',
    props: {
        display: {
            type: Boolean,
            default: false
        },
        value: {
            type: [Number, String]
        },
        isloading: {
            type: Boolean,
            default: false
        },
        disabled: Boolean,
        placeholder: {
            type: String,
            default: ' ' // is used to trigger the floating label so it can not be empty
        },
        label: String,
        required: Boolean,
        error: Boolean,
        errortext: String
    },
    methods: {
        handleInput: function(value) {
            this.$emit('input', value)
        },
        clicked: function(event) {
            this.$emit('click', event)
        }
    },
    computed: {
        inputid: function() {
            return `input-${this._rng(5)}`
        }
    }
}
</script>

<style scoped>
.input-wrapper {
    position: relative;
}

.input-wrapper > .input-label {
    position: absolute;
    font-size: 1em;
    left: 10px;
    background: #fff;
    top: 10px;
    padding: 0px 5px;
    transition: .1s all;
    cursor: text;
}

/* https://zellwk.com/blog/check-empty-input-css/ */
input:not(:placeholder-shown) + .input-label,
input:focus + .input-label {
    top: -5px;
}

input {
    width: 100%;
    height: 30px;
    box-sizing: border-box;
    border-radius: 5px;
    padding-left: 10px;
    border: 1px solid #064A6C;
    border-radius: 5px;
    font-size: 1.2em;
}

input::placeholder {
    font-size: 1em;
}

input.with-label {
    padding-top: 5px;
    height: 35px;
}

.textinput-container > label {
    color: #fff;
    font-weight: bold;
}

.input-label {
    font-size: 1.2em;
    color: #2c2e2f;
}

.error-container {
    margin-top: 3px;
    padding-left: 10px;
    color: red;
}

.error-container > .error-label {
    visibility: hidden;
}

.error-container > .error-label.show {
    visibility: visible;
}

.input-wrapper.error >>> * {
    color: red;
}

.input-wrapper.error > input {
    border: 1px solid red;
}
</style>