<template>
    <div class="ticket-container">
        <div class="ticket" @click="addToCart">
            <div class="price-container">
                <label>{{ this.price }}</label>
            </div>

            <div class="name-container">
                <label >{{ this.ticket.ticketName }}</label>
            </div>

            <v-icon class="cart" name="shopping-cart" scale="1.5" />
        </div>
    </div>
</template>

<script>
import Vue from 'vue'

import 'vue-awesome/icons/shopping-cart'

export default {
    name: 'TicketDepiction',
    props: {
        ticket: Object
    },
    methods: {
        addToCart: function() {
            Vue.$toast.info(this.$t('general.info.ticketaddedtocart'))
            this.$emit('addtocart', this.ticket)
        }
    },
    computed: {
        price: function() {
            return `${this._currencyValueToFloat(this.ticket.price)}${this._getCurrencySymbol(this.ticket.currency)}`
        },
        class: function() {
            if (this.ticket.travelClass === 'SECOND') {
                return '2. Klasse'
            } else if (this.ticket.travelClass === 'FIRST') {
                return '1. Klasse'
            } else {
                return ''
            }

        }
    }
}
</script>

<style scoped>
.ticket-container {
    position: relative;
    padding: 0 30px;
    box-sizing: border-box;
    color: #2c2e2f;
}

.ticket-container > .ticket {
    border-radius: 20px;
    box-shadow: 0px 1px 10px rgb(100 100 150 / 70%);
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
}

.ticket-container > .ticket:hover {
    background: #00000022;
    cursor: pointer;
}

.ticket-container > .ticket > .price-container {
    flex: 2;
    font-size: 1.2em;
    font-weight: bold;
    margin: 0 5px;
}

.ticket-container > .ticket > .name-container {
    flex: 7;
    text-align: left;
    word-wrap: break-word;
}

.ticket-container > .ticket > .name-container > label {
    font-size: 1.1em;
}

.ticket-container > .ticket > .name-container > label:hover,
.ticket-container > .ticket > .price-container > label:hover {
    cursor: pointer;
}

.ticket-container > .ticket > .cart {
    flex: 1;
    margin-right: 5px;
    visibility: hidden;
}

.ticket-container > .ticket:hover > .cart {
    visibility: visible;
}
</style>