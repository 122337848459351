<template>
    <TextInput @click="openResults" v-model="inputvalue" :disabled="disabled" :placeholder="placeholder" :display="display" class="text-input">
        <LoadingEllipsis v-if="isloading" class="spinner" color="#064A6C"/>
        <ul v-if="resultsopen" v-click-outside="closeResults">
            <li v-for="result in resultlist" :key="result.displayid" @click="handleSelection($event, result)">{{ result.displayname }}</li>
        </ul>
    </TextInput>
</template>

<script>
import TextInput from './elements/TextInput.vue'
import LoadingEllipsis from './elements/LoadingEllipsis.vue'

export default {
    name: 'AutoCompleteSearch',
    props: {
        value: String,
        isloading: Boolean,
        placeholder: String,
        disabled: Boolean,
        display: Boolean,
        resultlist: Array,
        label: String
    },
    data() {
        return {
            resultsopen: false
        }
    },
    methods: {
        handleSelection: function(event, result) {
            this.$emit('selection', result)
        },
        closeResults: function() {
            this.resultsopen = false
        },
        openResults: function() {
            this.resultsopen = true
        }
    },
    computed: {
        inputvalue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
    watch: {
        resultlist: function(value) {
            this.resultsopen = value.length > 0
        },
        inputvalue: function() {
            this.resultsopen = false
        }
    },
    components: {
        TextInput,
        LoadingEllipsis
    }
}
</script>

<style>
/* 
    override default input style
    -> since the component is imported, we can not do this in a scoped style
*/
.text-input > input {
    border: none;
}
</style>

<style scoped>
.text-input {
    position: relative;
    width: 100%;
}

.text-input > .spinner {
    position: absolute;
    right: 0;
    top: calc(50% - 6px);
    transform: scale(0.7);
}

.text-input > ul {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background: #fff;
    position: absolute;
    top: 15px;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    list-style-type: none;
    overflow: hidden;
    box-shadow: 0px 1px 10px rgb(100 100 150 / 70%);
    z-index: 10;
}

.text-input > ul > li {
    text-align: left;
    text-decoration: none;
    padding: 5px 0;
    padding-left: 10px;
    font-size: 1.3em;
}

.text-input > ul > li:hover {
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer;
}


</style>